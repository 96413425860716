import {
  LogoIcon,
  Box,
  Text,
  Inline,
  Stack,
  getButtonClassName,
  ButtonLink,
} from "@cashbook/web-components"
import config from "../../config"
import Page from "./Page"
import { getDeviceOS } from "@cashbook/device-info"

export default function MobileWebNotSupported() {
  const deviceOs = getDeviceOS()
  return (
    <Page
      title={
        <Inline gap="2" alignItems="center">
          <LogoIcon size="8" />
          <Text as="span" color="blue900">
            {config.appTitle}
          </Text>
        </Inline>
      }
      footer={
        <Box bgColor="surfacePrimary" padding="4">
          <Inline alignItems="center" justifyContent="between">
            <Text fontSize="c3" color="white">
              Get our iOS/Android app for uninterrupted services. Thank you.
            </Text>
            <Box
              padding="0"
              textAlign="right"
              bgColor="surfaceDefault"
              rounded="md"
              className="w-26 align-middle"
            >
              <ButtonLink
                href={`${
                  deviceOs === "ios"
                    ? config.appStoreDownloadLink
                    : config.appDownloadLink
                }`}
                className={getButtonClassName({ size: "sm" })}
                tabIndex={-1}
                target="_blank"
                color="textPrimary"
              >
                Download App
              </ButtonLink>
            </Box>
          </Inline>
        </Box>
      }
    >
      <Stack
        minHeight="full"
        bgColor="surfaceDefault"
        paddingTop="24"
        paddingX="4"
        gap="6"
      >
        <Stack gap="1" alignItems="center" textAlign="center">
          <LogoIcon size="16" />
          <Text fontSize="h4" color="blue900">
            {config.appTitle}
          </Text>
        </Stack>
        <Stack
          bgColor="surfaceNeutralLowest"
          borderRadius="md"
          paddingX="4"
          paddingY="6"
          gap="4"
        >
          <Inline gap="4" alignItems="center">
            <Box
              width="2"
              height="2"
              borderRadius="full"
              bgColor="iconMedium"
            />
            <Box flex="1">
              <Text fontSize="b3">
                CashBook Web is not supported on mobile devices.
              </Text>
            </Box>
          </Inline>
          <Inline gap="4" alignItems="center">
            <Box
              width="2"
              height="2"
              borderRadius="full"
              bgColor="iconMedium"
            />
            <Box flex="1">
              <Text fontSize="b3">
                Please use laptop/desktop to access CashBook Web.
              </Text>
            </Box>
          </Inline>
          <Inline gap="4" alignItems="center">
            <Box
              width="2"
              height="2"
              borderRadius="full"
              bgColor="iconMedium"
            />
            <Box flex="1">
              <Text fontSize="b3">
                Download iOS/Android {config.appTitle} app for uninterrupted
                services on Mobile.
              </Text>
            </Box>
          </Inline>
        </Stack>
      </Stack>
    </Page>
  )
}
