import { useCallback, useEffect, useRef, useState } from "react"
import {
  CopyTransactionsToCashbookPayload,
  DownloadBusinessTransactionsReportPayload,
  copyTransactionsToCashbook,
  deleteTransactionAttachment,
  downloadBusinessTransactions,
  getTransactionDetails,
  addTransactionAttachment,
  getTransactionAttachmentUrl,
  getTransactionAttachments,
  updateTransactionRemark,
} from "./services"
import { AttachmentType, PaymentsTransaction } from "./types"
import { fileToBase64, saveBlobAs } from "@cashbook/util-general"
import { toast } from "react-hot-toast"
import { logError } from "@cashbook/util-logging"
import { EntryAttachmentInfo } from "@cashbook/data-store/books"

export type Pagination = {
  skip: number
  take: number
}

export type DownloadBusinessTransactionsReportFilters = {
  reportType: "pdf" | "csv"
  memberId?: string
  from_datetime?: Date
  to_datetime?: Date
  partyId?: string
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
}

export function useDownloadBusinessTransactionsReport() {
  const [status, setStatus] = useState<{
    loading: "init" | "in_progress" | "success" | "failed"
    type?: "pdf" | "csv"
  }>({ loading: "init" })
  const downloadReport = useCallback(
    async (
      businessId: string,
      fileName: string,
      filters: DownloadBusinessTransactionsReportFilters,
      paymentCollectionsEnabled: boolean
    ) => {
      setStatus({ loading: "in_progress", type: filters.reportType })
      try {
        const constructedPayload: DownloadBusinessTransactionsReportPayload = {
          businessId,
          reportType: filters.reportType,
          paymentCollectionsEnabled: paymentCollectionsEnabled,
        }
        if (filters.from_datetime) {
          constructedPayload.from_datetime = filters.from_datetime.toISOString()
        }
        if (filters.to_datetime) {
          constructedPayload.to_datetime = filters.to_datetime.toISOString()
        }
        if (filters.status) {
          constructedPayload.status = filters.status
        }
        if (filters.memberId) {
          constructedPayload.memberId = filters.memberId
        }
        if (filters.partyId) {
          constructedPayload.partyId = filters.partyId
        }
        if (filters.attachments) {
          constructedPayload.attachments = filters.attachments
        }
        const response = await downloadBusinessTransactions<Blob>({
          ...constructedPayload,
        })
        if (!response) {
          throw new Error(
            `Not able to generate ${
              filters.reportType === "pdf" ? "PDF" : "Excel"
            } report at the moment.`
          )
        }
        setStatus((prev) => {
          return { ...prev, loading: "success" }
        })
        saveBlobAs(response, fileName)
        toast.success(`Successfully Downloaded. Please check your downloads.`)
      } catch (e) {
        const error = e as Error
        logError(error)
        setStatus((prev) => {
          return { ...prev, loading: "failed" }
        })
        toast.error(
          `${
            error.message || `Can't generate PDF at the moment.`
          } Please try again later!`
        )
      }
    },
    []
  )
  return {
    status,
    downloadReport,
  }
}

type AttachmentResponse = {
  thumbUrl: string[]
  url?: string[]
}
export function useGetAttachment() {
  const [state, setState] = useState<
    | { error: null; data: null; status: "init" }
    | { error: null; data: null; status: "in_progress" }
    | { error: null; data: AttachmentResponse; status: "success" }
    | { error: Error; data: null; status: "failed" }
  >({ status: "init", error: null, data: null })
  const getTransactionAttachmentDetails = useCallback(
    async (attachmentId: number) => {
      setState({ status: "in_progress", error: null, data: null })
      try {
        const response = await getTransactionAttachmentUrl<AttachmentResponse>({
          id: attachmentId,
        })
        setState({ status: "success", error: null, data: response })
        return response
      } catch (e) {
        const err = e as Error
        setState({ status: "failed", error: err, data: null })
        throw err
      }
    },
    []
  )

  return {
    state,
    getAttachment: getTransactionAttachmentDetails,
  }
}

export const DAYS_TILL_ADDITIONAL_NOTE_EDIT_ALLOWED = 30

export function useTransactionDetails(
  businessId: string,
  transactionId: string,
  npciTransactionId?: string,
  isBillAttached?: boolean
) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true)
  const [transaction, setTransaction] = useState<
    PaymentsTransaction | undefined
  >(undefined)
  const [attachments, setAttachments] = useState<{
    [id: string]: EntryAttachmentInfo
  }>({})
  const [transactionNoteUpdateApiLoading, setTransactionNoteUpdateApiLoading] =
    useState<boolean>(false)

  const transactionNoteRef = useRef<string | null>(null)

  const getTransaction = useCallback(async () => {
    try {
      const response = await getTransactionDetails<PaymentsTransaction>({
        transactionId,
        businessId,
      })
      if (isBillAttached && npciTransactionId) {
        const response = await getTransactionAttachments<{
          data: AttachmentType[]
          note: string | null
        }>({
          npciTransactionId: npciTransactionId,
        })
        if (response.data) {
          const attachmentsMappedWithId: { [id: string]: EntryAttachmentInfo } =
            {}
          response.data.forEach((attachment) => {
            attachmentsMappedWithId[attachment.id || ""] = {
              url: attachment.url?.[0] || "",
              thumbUrl: attachment.thumbUrl?.[0] || "",
              fileName: "",
              mimeType: attachment.mime_type || "image/png",
              id: attachment.id || "",
            }
          })
          setAttachments(attachmentsMappedWithId)
        }
      }
      setIsLoading(false)
      if (!response.id) {
        throw new Error(
          "Something went wrong. While fetching transaction details for you."
        )
      }
      transactionNoteRef.current = response.note || null
      setTransaction(response)
    } catch (e) {
      const err = e as Error
      setIsLoading(false)
      setError(err)
    }
  }, [transactionId, isBillAttached, npciTransactionId, businessId])

  const onUpdateTransactionNote = useCallback(
    async (updatedNote: string) => {
      try {
        setTransactionNoteUpdateApiLoading(true)
        const response = await updateTransactionRemark<{
          data: {
            note: string
          }
        }>({
          businessId: businessId,
          npciTransactionId: npciTransactionId || "",
          note: updatedNote,
        })
        transactionNoteRef.current = response?.data?.note || ""
      } catch (e) {
        const error = e as Error
        toast.error(error?.message || "Failed to update transaction note")
      } finally {
        setTransactionNoteUpdateApiLoading(false)
      }
    },
    [businessId, npciTransactionId]
  )

  function retryFetchingDetails() {
    setIsLoading(true)
    setShouldApiCall(true)
  }

  useEffect(() => {
    if (shouldApiCall) {
      getTransaction()
      setShouldApiCall(false)
    }
  }, [shouldApiCall, getTransaction])

  return {
    error,
    attachments,
    transaction,
    transactionNote: transactionNoteRef.current,
    transactionNoteUpdateApiLoading,
    loading: isLoading,
    retry: retryFetchingDetails,
    onUpdateTransactionNote,
  }
}

export function useTransactionAttachment() {
  const addAttachment = useCallback(
    async (file: File, npciTransactionId: string) => {
      try {
        const base64String = await fileToBase64(file)
        const response = await addTransactionAttachment<{
          data: {
            id: string
            transaction_id: string
            mime_type: string
            thumbUrl: string
            url: string
          }
        }>({
          fileName: file.name,
          mimeType: file.type,
          npciTransactionId,
          fileBase64: base64String,
        })
        return response
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    []
  )

  const deleteAttachment = useCallback(async (attachmentId: number) => {
    try {
      const response = await deleteTransactionAttachment({ id: attachmentId })
      return response
    } catch (e) {
      const error = e as Error
      throw error
    }
  }, [])

  return {
    deleteAttachment,
    addAttachment,
  }
}

export function useCopyToCashbook() {
  return useCallback(async (data: CopyTransactionsToCashbookPayload) => {
    try {
      await copyTransactionsToCashbook(data)
      return true
    } catch (e) {
      const err = e as Error
      throw err
    }
  }, [])
}
