import { ArrowLeftIcon, Box, Inline, Heading } from "@cashbook/web-components"
import { Link } from "react-router-dom"
import { $PropertyType } from "utility-types"

export default function SmPage({
  backTo,
  title,
  actions,
  children,
  footer,
  bgColor,
}: {
  backTo?: string | React.ReactNode
  title: React.ReactNode
  actions?: React.ReactNode
  children: React.ReactNode
  footer?: React.ReactNode
  bgColor?: $PropertyType<React.ComponentProps<typeof Box>, "bgColor">
}) {
  return (
    <Box position="relative" width="full" bgColor={bgColor || "blue50"}>
      <Box
        as="nav"
        position="sticky"
        top="0"
        width="full"
        height="14"
        bgColor="white"
        paddingX="4"
        borderBottomWidth="1"
        borderColor="gray100"
        zIndex="50"
      >
        <Inline height="full" alignItems="center" gap="4">
          {!backTo ? null : typeof backTo === "string" ? (
            <Link to={backTo}>
              <ArrowLeftIcon />
            </Link>
          ) : (
            backTo
          )}
          <Box flex="1">
            <Heading as="h1">{title}</Heading>
          </Box>
          {actions ? <Box>{actions}</Box> : null}
        </Inline>
      </Box>
      <Box
        as="main"
        paddingBottom={footer ? "16" : "0"}
        className="h-screen"
        overflow="auto"
      >
        {children}
      </Box>
      {footer ? (
        <Inline
          as="footer"
          position="fixed"
          bottom="0"
          width="full"
          height="16"
          bgColor="white"
          zIndex="50"
          borderTopWidth="1"
          borderColor="gray100"
          alignItems="center"
          justifyContent="center"
        >
          {footer}
        </Inline>
      ) : null}
    </Box>
  )
}
